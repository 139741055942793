<!-- 累充活动 -->
<template>
  <div v-if="!disabled" class="relative z-10 flex justify-center w-full">
    <div
      v-imloading="loading"
      :class="isMobile ? 'gears_wrap_mobile w-full' : 'gears_wrap_desk w-full'"
    >
      <div
        :class="['activity_info flex items-center justify-between']"
      >
        <div class="flex items-center">
          <span>{{ $t('siteBuild.consecutiveWidget.subtitle') }}：{{ hasDay }} </span>
        </div>
        <span class="flex items-center"><i class="el-icon-timer text-[24px]" />：{{sTime}}</span>
      </div>
      <i-progress :class="isMobile ? 'mobile' : ''" :is-mobile="isMobile" :percent="editing ? 50 : progressPercent" :info-text="infoText" :model="model" :data="gearsData" />
      <div class="w-full flex">
        <div class="gears-content">
          <div v-for="(item, index) in gearsData" :key="`gears_${index}`" class="py-[10px]">
            <bg-style
              :class="['gears-wrap-list w-full rounded overflow-hidden']"
              :bg="model.giftBackground"
            >
              <div class="gift_info__node relative mb-2 w-full">Day {{ item.day_no }}</div>
              <div class="gift_info">
                <el-scrollbar class="w-full">
                  <div class="gift_list flex w-full">
                    <bg-style
                      v-for="(gift, idx) in item.gift_list"
                      :key="`gift_${index}_${idx}`"
                      class="gift_list_row_item flex relative flex-shrink-0"
                      :bg="model.giftItemBackground"
                    >
                      <img :src="gift.gift_goods_logo" class="w-[60px] h-[60px] flex-shrink-0 relative" alt />
                      <div class="item-num relative" :style="numberColorStyle">{{gift.num}}</div>
                      <bg-style
                        v-if="item.level_status === 3 && model.receivedMaskShow"
                        class="absolute top-0 left-0 bottom-0 right-0 z-30"
                        :bg="model.receivedMask"
                      ></bg-style>
                    </bg-style>
                  </div>
                </el-scrollbar>
                <div class="flex items-center justify-center item-gift pl-10">
                  <!-- 未达标 -->
                  <bg-style
                    v-if="item.get_status === 3"
                    :bg="model.nqBtnBackground"
                    class="sum_btn"
                  >
                    <span class="relative whitespace-nowrap" :style="{ color: model.nqBtnColorShow ? model.nqBtnColor : 'unset' }">{{$t('siteBuild.activity.notQualified')}}</span>
                  </bg-style>
                  <!-- 领取 -->
                  <bg-style
                    v-if="item.get_status === 2"
                    :bg="model.getBtnBackground"
                    class="sum_btn cursor-pointer"
                    @click.native="handlePick(item)"
                  >
                    <span class="relative whitespace-nowrap" :style="{ color: model.getBtnColorShow ? model.getBtnColor : 'unset' }">{{$t('siteBuild.activity.get')}}</span>
                  </bg-style>
                  <!-- 已领取 -->
                  <bg-style
                    v-if="item.get_status === 1"
                    :bg="model.receivedBtnBackground"
                    class="sum_btn"
                  >
                    <span class="whitespace-nowrap relative" :style="getTextColor(model.receivedBtnColor, model.receivedBtnColorShow)">{{$t('siteBuild.activity.haveGet')}}</span>
                  </bg-style>
                </div>
              </div>
            </bg-style>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="p-5 text-center">
    <span class="text-24">活动已结束或未开启</span>
  </div>
</template>
<script>
import chunk from 'lodash.chunk'
import isNil from 'lodash.isnil'
import { mapState } from 'vuex'
import { Message } from "element-ui";
import moment from 'moment'
import IProgress from "./progress.vue";
import {formatZoneTime, getTextColor} from '~/utils'
import Bus from '~/site/model/bus'
import { isBuildWebSite, isPre, GET_SITE_ID } from '~/config'

let isPreviewMode = !isBuildWebSite
const SiteID = GET_SITE_ID()

if (isBuildWebSite && isPre && SiteID.startsWith('56') && SiteID.length === 2) {
  // eslint-disable-next-line no-unused-vars
  isPreviewMode = true
}

export default {
  name: 'SumComponents',
  components: {
    IProgress
  },
  props: {
    model: {
      type: Object,
      default: () => { }
    },
    isMobile: Boolean,
    isHaveGiftRowBg: Boolean,
    editing: Boolean
  },
  data() {
    return {
      loading: false,
      activityData: {},
      gearsData: [],
      sTime: '00:00:00',
      progressPercent: 0,
      totalNumber: 0,
      maxDay: 0,
      hasDay: 0,
      disabled: false
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    // 数字颜色
    numberColorStyle() {
      const { numberColorShow, numberColor } = this.model
      return numberColorShow ? { color: numberColor } : { color: 'unset' }
    },
    infoText() {
      return `${this.hasDay}/${this.maxDay}`
    }
  },
  mounted() {
    this.getActivityData()
    Bus.$on('reloadActivity', () => {
      this.getActivityData()
    })
  },
  destroyed() {
    Bus.$off('reloadActivity')
  },
  methods: {
    getTextColor,
    // 领取
    async handlePick(item) {
      if (this.editing) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
      } else {
        const params = {
          activity_id: this.activityData.biz_id,
          day_no: item.day_no
        }
        const [err] = await this.$utils.to(this.$api.activity.rechargePick(params))
        if (!err) {
          Message.success('Success')
          await this.getRechargeList()
        } else {
          Message.error(err.message)
        }
      }
    },
    async getRechargeList () {
      if (!this.userInfo) return
      const params = {
        activity_id: this.activityData.biz_id
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getRechargeList(params))
      if (!err) {
        this.hasDay = res.has_recharged_days
        this.maxDay = res.max_day
        this.gearsData = res.recharge_list
      }
    },
    activityUnusual() {
      this.disabled = true
    },
    clearData() {
      this.hasDay = 0
      this.maxDay = 0
    },
    async getActivityData() {
      if (this.loading) return
      this.clearData()
      this.loading = true
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const params = {
        now_time: now_stem,
        activity_type: 7,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || ''
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params, { editing: this.editing }))
      this.loading = false
      if (!err) {
        const data = (res.list || []).filter(item => item.status === 2)[0]
        if (!data) {
          this.activityUnusual()
          return
        }
        const rule = JSON.parse(data.activity_rule)
        this.activityData = data
        this.maxDay = rule.recharge_list[rule.recharge_list.length - 1]?.day_no || 0
        this.gearsData = rule.recharge_list.map((item, index) => {
          const newItem = {...item}
          newItem.get_status = this.editing ? (index + 1) : 3
          return newItem
        })
        this.countTime()
        await this.getRechargeList()
        this.progressPercent = (this.hasDay / this.maxDay) * 100
      } else {
        Message.error(res)
      }
    },
    countTime() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.activityData.stop_time * 1000).value
      const leftTime = end - now;
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.sTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.countTime, 1000);
    },
    // 分组
    chunkGiftData(arr) {
      return chunk(arr, this.isMobile ? 4 : 9)
    }
  },
}
</script>
<style lang="less">
.gears_wrap_desk {
  .gears-content {
    width: 100%;
  }
  .activity_info {
    font-family: PingFang SC;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .gift_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .gift_list {
      column-gap: 24px;
      .gift_list_row_item {
        width: 86px;
        height: 86px;
        padding-top: 5px;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        .item-num {
          //min-height: 24px;
          //font-size: 14px;
          //line-height: 22px;
          //text-align: center;
          //position: absolute;
          //bottom: 5px;
          //right: 15px;
          //font-weight: bold;
        }
      }
    }

    .sum_btn {
      min-width: 80px;
      height: 36px;
      border-radius: 2px;
      padding: 0 12px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .gears-wrap-list {
    padding: 24px;
  }
}

.gears_wrap_mobile {
  .gears-content {
    width: 100%;
  }
  .activity_info {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .gears-wrap-list {
    padding: 12px;
  }
  .gift_info {
    display: flex;
    flex-direction: column;
    .gift_list {
      gap: 8px;
      .gift_list_row_item {
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        padding-top: 5px;
      }
    }

    .item-gift {
      margin-top: 16px;
      padding-left: 0 !important;
    }

    .sum_btn {
      min-width: 72px;
      height: 32px;
      border-radius: 2px;
      padding: 0 8px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
