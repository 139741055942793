<template>
  <bg-style v-loading="loading" class="flex items-center justify-center relative text-[30px] font-bold cart-button" :class="{ 'mobile': isMobile && !forcePc }" :bg="model.shopCartBackground" @click.native.stop="handleClick">
    <i class="triangle" :class="{ 'in-cart': goodsNumber > 0 && isFirst, 'is-ready': goodsNumber > 0 && !isFirst }" :style="getTextColor(model.shopCartColor)" />
    <el-badge :value="goodsNumber" :hidden="goodsNumber <= 0" class="shop-badge">
      <im-icon icon="icon-gouwuche1" class="relative icon" :style="getTextColor(model.shopCartColor)" />
    </el-badge>
  </bg-style>
</template>

<script>
import { mapGetters } from 'vuex'
import { Message } from "element-ui";
import isNil from "lodash.isnil";
import {StoreWidgetModel} from '~/site/model'
import {DeviceEnum} from "~/enums/deviceEnum";
import {getTextColor} from "~/utils";

export default {
  name: 'StoreShopCart',
  components: {
    //
  },
  props: {
    model: StoreWidgetModel,
    forcePc: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    good: {
      type: Object,
      default() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    number: {
      type: [Number],
      default: 0
    }
  },
  data () {
    return {
      isFirst: false,
      loading: false
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapGetters(['shopCart']),
    goodsNumber() {
      return this.shopCart[this.good.id] || 0
    }
  },
  methods: {
    getTextColor,
    checkStatus() {
      let result = true
      if (this.disabled) {
        result = false
        return result
      }
      if (isNil(this.$store.state.user.siteLoginUserInfo?.player_id)) {
        this.$store.commit('user/SET_LOGINSHOW', true)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        // this.$logger.captureMessage('点击购买，未登录，弹出登录弹窗')
        result = false
      }
      return result
    },
    async handleClick() {
      if (!this.checkStatus()) return
      const oldGoodsNumber = Number(this.goodsNumber)
      const params = {
        player_id: this.$store.state.user.siteLoginUserInfo.player_id,
        project_id: this.$store.state.project.projectId,
        goods_id: this.good.id,
        goods_num: 1,
        site_id: this.$store.state.user.siteLoginUserInfo.site_id
      }
      this.loading = true
      const [err] = await this.$utils.to(this.$api.good.saveToShopCart(params))
      this.loading = false
      if (!err) {
        if (oldGoodsNumber === 0) this.isFirst = true
        this.$store.commit('goods/ADD_GOOD_NUM', params.goods_id)
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.cart-button {
  width: 44px;
  height: 44px;
  .el-loading-spinner .path {
    stroke: @primary-1;
  }
  .icon {
    width: 26px;
    height: 26px;
  }
  &.mobile {
    width: 26px;
    height: 26px;
    .icon {
      width: 18px;
      height: 18px;
    }
    .shop-badge {
      line-height: 10px;
      .el-badge__content.is-fixed {
        transform: translateY(-50%) translateX(100%) scale(0.7);
      }
    }
    .triangle {
      display: none;
      &.in-cart {
        animation: mobile-down 0.24s ease-in-out forwards;
        animation-delay: 250ms;
      }
      &.is-ready {
        top: 14.5px;
        height: 8px;
        opacity: 1;
        animation: none !important;
      }
    }
  }
  .triangle {
    width: 10px;
    height: 8px;
    border: 2px solid currentColor;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 18px;
    opacity: 0;
    &.in-cart {
      animation: down 0.24s ease-in-out forwards;
      animation-delay: 250ms;
    }
    &.is-ready {
      top: 13px;
      height: 8px;
      opacity: 1;
      animation: none !important;
    }
  }
  @keyframes down {
    0% {
      top: 0;
    }
    50% {
      top: 20px;
      height: 4px;
      opacity: 1;
    }
    100% {
      top: 13px;
      height: 8px;
      opacity: 1;
    }
  }
  @keyframes mobile-down {
    0% {
      top: 0;
    }
    50% {
      top: 18.5px;
      height: 4px;
      opacity: 1;
    }
    100% {
      top: 14.5px;
      height: 8px;
      opacity: 1;
    }
  }
}
</style>
